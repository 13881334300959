<template>
    <div>
        <Quote title="Bu yerda siz izohingizni o'zgartirishingiz mumkin"/>
        <GoBack next="Izohingizni o'zgartiring"/>
        <div class="row justify-content-center" style="margin-top:111px;">
            <div class="col-8">
                <form @submit.prevent="editMyComment">
                    <div class="row div6 justify-content-end">
                        <div class="col-12 mb-3">
                            <label for="exampleInputId2" class="form-label"></label>
                            <select
                                v-model="comment.course"
                                class="form-select form-control p-10"
                                id="exampleInputId2"
                                required
                            >
                                <option value="" disabled selected hidden>Kursni tanlang...</option>
                                <option
                                    v-for="course in getMyCourses"
                                    :key="course.id"
                                    :label="course.course.name"
                                    :value="'/api/courses/' + course.course.id"
                                />
                            </select>

                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end mb-1">
                        <div class="col-12 col-sm-12 col-md-7">
                            <span
                                v-if="getImage"
                                class="w-100 text-lg-end d-inline-block pt-1 text-primary text-truncate"
                            >
                            {{ getImage.contentUrl.slice(21, 100) }}
                        </span>
                            <input
                                id="image"
                                style="display: none"
                                type="file"
                                accept="image/*"
                                @change="setImage($event)"
                            >
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mb-3">
                        <label
                            for="image"
                            class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 align-content-center image"
                        >
                            Rasm yuklang
                        </label>
                    </div>
                    <div class="form-group mb-4">
                        <div class="input-group">
                            <textarea
                                v-model="comment.text"
                                class="form-control area"
                                placeholder="Izoh qoldiring"
                                aria-label="Width textarea"
                            ></textarea>
                        </div>
                    </div>
                    <div class="row justify-content-end mb-3">
                        <div class="text-end">
                            <button-component
                                :class="'col-12 col-md-6 col-lg-5 col-xl-4 btn btn-primary'"
                                :disabled="!comment.text.length"
                            >
                                O'zgartirish
                            </button-component>
                        </div>
                    </div>
                </form>
                <div>
                    <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Izohingiz muvaffaqiyatli o'zgartirildi</h3>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hideErrorModal"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>
            </div>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import {mapActions, mapGetters} from "vuex";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
    name: "EditMyComments",
    components: {
        ButtonComponent,
        Quote,
        GoBack
    },
    data() {
        return {
            show: true,
            comment: {
                id: null,
                text: '',
                image: '',
                course: ''
            },
            media: {
                file: null,
            },
        }
    },
    computed: {
        ...mapGetters(['getComment', 'isAdmin', 'getImage', 'getMyCourses'])
    },
    methods: {
        ...mapActions(['fetchMyComment', 'putComment', 'pushImage', 'fetchMyCourses']),
        editMyComment() {
            if (this.comment.text !== this.getComment.text || this.comment.course !== this.getComment.course || this.comment.image !== this.getComment.image) {
                this.putComment(this.comment)
                    .then(() => {
                        this.$refs['modal-success'].show()
                        setTimeout(this.hideErrorModal, 3000)
                    })
            } else {
                this.$router.push('/comments')
                setTimeout(this.errorModal, 5000)
            }
        },
        setImage(event) {
            this.media.file = event.target.files[0];
            const data = new FormData();
            data.append('file', this.media.file);
            this.pushImage(data)
                .then(() => {
                    this.comment.image = this.getImage['@id']
                })
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            if (this.isAdmin) {
                this.$router.push('/comments')
            } else {
                this.$router.push('/my-comments')
            }
        },
    },
    mounted() {
        this.show = true
        this.fetchMyComment(this.$route.params.id)
            .then(() => {
                this.comment.id = this.getComment.id
                this.comment.text = this.getComment.text
                this.comment.course = this.getComment.course
                this.comment.image = this.getComment.image
            })
        this.fetchMyCourses()
        this.show = false
    }
}
</script>

<style scoped>
* {
    padding: 0;
    margin: 0;
}

.form-control {
    border-radius: 10px !important;
    border: 1px solid #80007F !important;;
    box-sizing: border-box !important;
    padding-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

.image {
    height: 37px;
    text-align: center;
    padding: 5px 0;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    cursor: pointer;
    color: #FFFFFF;
}

.image:hover {
    background-color: #990098 !important;
    border-color: #990098;
}

.area {
    height: 237px;
    padding-top: 9px;
    padding-left: 17.04px;
}

div.div_input_file {
    display: flex;
    gap: 2.5%;
}

div.div_input_file > button {
    border: none;
    background: #80007F;
    color: white;
    padding: 0 3%;
    border-radius: 10px;
    width: 161px;
    height: 38px;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.justify-content-end .btn-primary {
    text-align: center;
    padding: 5px 60px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F !important;
    outline: none;
    border-color: #80007F;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098;
}

</style>
